import { useEffect, useRef, type ReactNode } from "react";

const ClickAwayComponent = ({
  onClickAway,
  children,
  disabled,
}: {
  onClickAway: () => void;
  children: ReactNode;
  disabled?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (disabled) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway();
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClickAway();
      }
    };

    const handleInsideClick = (event: MouseEvent) => {
      // Check if the click is inside the component and do not trigger onClickAway
      if (ref.current && ref.current.contains(event.target as Node)) {
        event.stopPropagation();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);
    document.addEventListener("mousedown", handleInsideClick, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
      document.removeEventListener("mousedown", handleInsideClick, true);
    };
  }, [onClickAway, disabled]);

  return <div ref={ref}>{children}</div>;
};

export default ClickAwayComponent;
