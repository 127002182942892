import { useAddress, useWallet } from "@meshsdk/react";
import { SupportedToken } from "@prisma/client";
import { Transaction } from "@meshsdk/core";
import {
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { env } from "~/env.mjs";
import { api } from "~/utils/api";
import { useGameStore, usePageStore } from "~/utils/zustand";
import ClickAwayComponent from "./ClickAwayComponent";
import { CloseIcon } from "./SvgIcons";

type ModalProps = {
  onClose: () => void;
  tabName: "deposit" | "withdraw";
};

const FundModal: FC<ModalProps> = ({ onClose, tabName }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isFundModalOpen } = usePageStore();

  useEffect(() => {
    document.body.style.overflow = isFundModalOpen ? "hidden" : "auto";
  }, [isFundModalOpen]);

  return isFundModalOpen ? (
    <div className="absolute left-0 right-0 top-0 z-[1000] flex items-center justify-center text-white backdrop-blur-md md:fixed md:inset-0">
      <ClickAwayComponent onClickAway={() => onClose()} disabled={isLoading}>
        <div className="relative w-[560px] rounded-2xl border-2 border-[#ffffff60] bg-[#170b3b] p-6 max-sm:w-[450px] max-[500px]:w-[380px] max-[400px]:w-[300px]">
          <button
            className="absolute right-5 top-5"
            onClick={() => onClose()}
            disabled={isLoading}
          >
            <CloseIcon color="#fff" />
          </button>

          {/* fund tabs beginning */}
          <button
            className={
              "rounded-t-md bg-[#732e9f] px-4 py-2 text-sm font-bold uppercase text-white"
            }
            // onClick={() => setTab(isFundModal)}
            disabled={isLoading}
          >
            {tabName}
          </button>
          {/* fund tabs end */}
          {tabName === "deposit" && (
            <ActionForm
              type="deposit"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              onClose={onClose}
            />
          )}
          {tabName === "withdraw" && (
            <ActionForm
              type="withdraw"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              onClose={onClose}
            />
          )}
        </div>
      </ClickAwayComponent>
    </div>
  ) : (
    <></>
  );
};

export default FundModal;

type FormValues = { [token in SupportedToken]?: string };

const ActionForm = ({
  type,
  isLoading,
  setIsLoading,
  onClose,
}: {
  type: "deposit" | "withdraw";
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  const { wallet } = useWallet();
  const address = useAddress();
  const { gameBalance } = useGameStore();

  const { mutateAsync: depositFunds } = api.user.deposit.useMutation();
  const { mutateAsync: withdraw_request } =
    api.user.withdraw_request.useMutation();
  const { mutateAsync: withdraw_tx_signed } =
    api.user.withdraw_tx_signed.useMutation();

  const { refetch: refetchDepositUpdate } =
    api.user.requestDepositUpdate.useQuery(undefined, { enabled: false });
  const { refetch: refetchWithdrawUpdate_UserSent } =
    api.user.requestWithdrawalUpdate_UserSent.useQuery(undefined, {
      enabled: false,
    });

  const { data: tokenSettings } = api.config.tokenSettings.useQuery();

  const { register, handleSubmit } = useForm<FormValues>();

  const onSubmit = async (data: FormValues) => {
    setIsLoading(true);
    const tokensSelected = (
      Object.keys(SupportedToken) as Array<keyof typeof SupportedToken>
    )
      .map((item) => ({
        name: item,
        amount: parseFloat(data[item] ?? "0"),
      }))
      .filter((e) => e.amount > 0);

    try {
      if (type === "deposit" && wallet) {
        const assetsToSend = tokensSelected
          .filter((e) => e.name !== "ADA")
          .map((e) => {
            const coin = tokenSettings?.settings.find(
              (coin) => coin.tokenName === e.name,
            );

            if (coin) {
              return {
                unit: coin.policyId,
                quantity: (e.amount * 10 ** coin.decimals).toString(),
              };
            }
          });
        const tx = new Transaction({ initiator: wallet });

        if (data.ADA && parseFloat(data.ADA ?? "") > 0) {
          tx.sendLovelace(
            env.NEXT_PUBLIC_OWNER_WALLET,
            (parseFloat(data.ADA) * 1000000).toString(),
          );
        }
        if (assetsToSend.length > 0) {
          tx.sendAssets(env.NEXT_PUBLIC_OWNER_WALLET, assetsToSend);
        }

        const utxos = await wallet.getUtxos();
        tx.setTxInputs(utxos);

        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx);
        const txHash = await wallet.submitTx(signedTx);

        const res = await depositFunds({
          tokensToDeposit: tokensSelected,
          sentTo: env.NEXT_PUBLIC_OWNER_WALLET,
          txHash,
        });

        if (res.success) {
          const id = toast.loading("Deposit Started. Processing...");
          toast.update(id, { toastId: "deposit-toast" });
          onClose();
        } else {
          toast.error(res.error);
          console.error(res);
        }

        await refetchDepositUpdate();
      } else if (type === "withdraw" && address) {
        if (
          tokensSelected.length > 0 &&
          tokensSelected.every((item) => gameBalance[item.name] >= item.amount)
        ) {
          const withdraw = await withdraw_request({
            tokensToWithdraw: tokensSelected,
          });

          if (withdraw.success) {
            const tx = new Transaction({ initiator: wallet });

            tx.sendLovelace(
              env.NEXT_PUBLIC_OWNER_WALLET,
              withdraw.fees.toString(),
            );

            const utxos = await wallet.getUtxos();
            tx.setTxInputs(utxos);

            console.log({ tx });
            console.log({ wallet });

            const unsignedTx = await tx.build();
            const signedTx = await wallet.signTx(unsignedTx);
            const txHash = await wallet.submitTx(signedTx);

            // send txHash back to server
            await withdraw_tx_signed({
              txHash,
            });

            const id = toast.loading("Withdrawal Started. Processing...");
            toast.update(id, { toastId: "withdraw-toast" });
            await refetchWithdrawUpdate_UserSent();
            onClose();
          } else {
            toast.error(withdraw.error);
            console.error(withdraw);
          }
        } else {
          toast.error("Not enough balance to withdraw!");
          setIsLoading(false);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(`${type} error:`, error);
      } else {
        toast.error("There was an error");
        console.error(`${type} error:`, error);
      }
    }
    setIsLoading(false);
  };

  return (
    <form
      className="border-t-2 border-[#732e9f] py-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <BalanceList />
      <div className="my-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
        {(
          Object.keys(SupportedToken) as Array<keyof typeof SupportedToken>
        ).map((item) => (
          <div key={`deposit_${item}`}>
            <label htmlFor={item} className="mb-2 text-sm font-bold uppercase">
              {item}
            </label>
            <input
              className="value-input h-10 w-full border border-yellow-300 bg-[#00000000] p-3 py-0.5 text-[16px]  font-bold text-white"
              id={item}
              placeholder={`${item} amount`}
              type="number"
              step={"0.001"}
              {...register(item, { required: false })}
            />
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center gap-4 sm:flex-row">
        <button
          className="w-[180px] cursor-pointer border bg-[#4e2080] px-5 py-2 uppercase duration-300 hover:bg-[#3b1762] disabled:cursor-not-allowed"
          disabled={isLoading}
          type="submit"
        >
          {isLoading ? `${type}ing...` : type}
        </button>
        <div className="mx-10 text-[10px]">
          After {type}ing, please wait while the transaction is verified on the
          blockchain.
          <br></br>A minUTxO fee is included in all transactions.
        </div>
      </div>
    </form>
  );
};

const BalanceList = () => {
  const { gameBalance } = useGameStore();

  return (
    <div className="tracking-wider">
      <p>Your current game balance: </p>
      <div className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2">
        {(
          Object.keys(SupportedToken) as Array<keyof typeof SupportedToken>
        ).map((item) => (
          <div
            key={`balance_${item}`}
            className="text-sm uppercase text-[#ddd]"
          >
            {item}:{" "}
            <span className="font-black text-[#6673dc]">
              {" "}
              {gameBalance[item].toFixed(2)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
