import Link from "next/link";

import { cn } from "~/utils/shadcn";
import Image from "next/image";
import { Menu, X } from "lucide-react";
import ConnectWallet from "./ConnectWallet";
import { usePageStore } from "~/utils/zustand";

type MainNavItem = {
  title: string;
  href: string;
  showRequirement: boolean;
  disabled?: boolean;
};

interface MobileNavProps {
  items: MainNavItem[];
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  children?: React.ReactNode;
}

export default function MobileNav({
  items,
  isOpen,
  setIsOpen,
  children,
}: MobileNavProps) {
  const { isFundModalOpen } = usePageStore();

  return (
    <div
      className={cn(
        "absolute inset-0 top-0 z-50 grid grid-flow-row auto-rows-max overflow-auto p-6 animate-in fade-in-5 md:hidden",
        isFundModalOpen ? "max-w-full" : isOpen ? "max-h-[480px]" : "h-fit",
      )}
    >
      <div className="relative z-20 grid gap-6 rounded-md bg-popover/80 p-4 text-popover-foreground">
        <div className="flex items-center justify-between">
          <Link href="/" className="hue-rotate flex items-center space-x-2">
            <Image
              src="/images/bottom-logo.png"
              width={75}
              height={75}
              alt="logo"
            />
            <p className="text-2xl text-white">Nebula Gaming</p>
          </Link>

          {isOpen ? (
            <X
              onClick={() => setIsOpen(false)}
              className="animate-in fade-in-5"
            />
          ) : (
            <Menu
              onClick={() => setIsOpen(true)}
              className="duration-300 animate-in fade-in-5"
            />
          )}
        </div>
        {isOpen && (
          <nav className="grid grid-flow-row auto-rows-max bg-popover/80 text-sm">
            {items.map(
              (item, index) =>
                item.showRequirement && (
                  <Link
                    key={index}
                    href={item.disabled ? "#" : item.href}
                    className={cn(
                      "flex w-full items-center rounded-md bg-popover/80 p-2 text-sm font-medium duration-300 animate-in fade-in-5 hover:underline",
                      item.disabled && "cursor-not-allowed opacity-60",
                    )}
                    onClick={() => setIsOpen(false)}
                  >
                    {item.title}
                  </Link>
                ),
            )}

            <ConnectWallet mobileCloseOnClick={() => setIsOpen(false)} />
          </nav>
        )}
        {children}
      </div>
    </div>
  );
}
