/* eslint-disable react-hooks/exhaustive-deps */
import type { Wallet } from "@meshsdk/core";
import { useAddress, useWallet, useWalletList } from "@meshsdk/react";
import { getCsrfToken, signIn, signOut, useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "~/utils/shadcn";
import { signMessage } from "../utils/util";
import FundModal from "./FundModal";
import { DownArrow } from "./SvgIcons";
import { Button } from "./ui/button";
import { H } from "@highlight-run/next/client";
import { useGameStore, usePageStore } from "~/utils/zustand";
import { api } from "~/utils/api";
import type { BalanceType } from "~/utils/types";
import { Howl } from "howler";

type ConnectWalletProps = {
  mobileCloseOnClick?: () => void;
};

const ConnectWallet = ({ mobileCloseOnClick }: ConnectWalletProps) => {
  const { status: sessionStatus } = useSession();

  const { connect, disconnect, connecting, wallet, connected } = useWallet();
  const [openTab, setOpenTab] = useState("deposit" as "deposit" | "withdraw");
  const [currentBalance, setCurrentBalance] = useState<number>(0);
  const [selectedWallet, setSelectedWallet] = useState<Wallet>();
  const [connectionRequested, setConnectionRequested] = useState(false);
  const walletLists = useWalletList();
  const address = useAddress();
  const { gameBalance, setUserGameBalance } = useGameStore();
  const { data: userBalance, refetch: refetchUserBalance } =
    api.game.getBalance.useQuery(undefined, {
      enabled: sessionStatus === "authenticated",
    });

  const [refetchDeposit, setRefetchDeposit] = useState(true);
  const [refetchWithdrawal_UserSent, setRefetchWithdrawal_UserSent] =
    useState(true);
  const [refetchWithdrawal_AdminSent, setRefetchWithdrawal_AdminSent] =
    useState(true);

  const { data: withdrawalUpdate_UserSent } =
    api.user.requestWithdrawalUpdate_UserSent.useQuery(undefined, {
      enabled: refetchWithdrawal_UserSent && sessionStatus === "authenticated",
      refetchInterval: 15000,
      retryDelay: (attemptIndex) => Math.min(5000 * 2 ** attemptIndex, 60000),
    });

  const {
    data: withdrawalUpdate_AdminSent,
    dataUpdatedAt: withdrawalUpdate_AdminSentStatus,
  } = api.user.requestWithdrawalUpdate_AdminSent.useQuery(undefined, {
    enabled: refetchWithdrawal_AdminSent && sessionStatus === "authenticated",
    refetchInterval: 15000,
    retryDelay: (attemptIndex) => Math.min(5000 * 2 ** attemptIndex, 60000),
  });

  const { data: depositUpdate } = api.user.requestDepositUpdate.useQuery(
    undefined,
    {
      enabled: refetchDeposit && sessionStatus === "authenticated",
      refetchInterval: 15000,
      retryDelay: (attemptIndex) => Math.min(5000 * 2 ** attemptIndex, 60000),
    },
  );

  const successSound = new Howl({
    src: "../sounds/effect-1.wav",
    volume: 0.5,
  });

  useEffect(() => {
    const myWallet = JSON.parse(
      localStorage.getItem("selectedWallet")!,
    ) as Wallet;

    if (myWallet) {
      void handleWalletSelection(myWallet);
    }
  }, []);

  useEffect(() => {
    if (address) {
      H.identify(address);
    }
  }, [address]);

  useEffect(() => {
    if (userBalance) {
      const gameBalance = {} as BalanceType;

      for (const balance of userBalance) {
        gameBalance[balance.tokenName] = Number(balance.amount);
      }

      setUserGameBalance(gameBalance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBalance, sessionStatus]);

  useEffect(() => {
    if (connected && wallet) {
      try {
        void wallet.getLovelace().then((adaValue) => {
          setCurrentBalance(parseInt(adaValue) / 1000000);
        });
      } catch (e) {
        if (e instanceof Error) {
          console.error(
            "ERROR getting wallet balance",
            { address, wallet },
            e.message,
          );
        }
      }
    }
  }, [gameBalance]);

  useEffect(() => {
    if (connected && sessionStatus === "unauthenticated") {
      disconnect();
    }
    if (sessionStatus === "authenticated" && !connected) {
      const myWallet = JSON.parse(
        localStorage.getItem("selectedWallet")!,
      ) as Wallet;
      void handleWalletSelection(myWallet);
    }
  }, [sessionStatus]);

  useEffect(() => {
    if (connected && address) {
      if (
        sessionStatus === "unauthenticated" &&
        !connecting &&
        connectionRequested
      ) {
        // prevent this to be called twice - maybe there's a better way to deal with this
        // I haven't spent enough time looking at this tbh, and this seems to work ok-ish
        setConnectionRequested(false);

        getCsrfToken()
          .then(async (csrfToken) => {
            const msg = `This msg is to validate you are the owner of this wallet!

        Nonce: ${csrfToken}
        `;

            const userAssets = await wallet.getAssets();

            console.log(
              "Wallet Address:",
              address,
              "Wallet Assets:",
              userAssets,
            );

            const { signature, key, bech32Address } = await signMessage(
              msg,
              selectedWallet!.name.toLowerCase(),
            );

            if (!signature || !key || !bech32Address) {
              throw new Error("Invalid signature/key/address");
            }

            const signInResponse = await signIn("credentials", {
              redirect: false,
              callbackUrl: "/",
              user_address: bech32Address,
              message: msg,
              signature,
              key,
            });

            if (!signInResponse?.ok) {
              throw new Error(`Error logging in!, ${signInResponse?.error}`);
            }

            const adaValue = await wallet.getLovelace();

            setCurrentBalance(parseInt(adaValue) / 1000000);

            H.identify(bech32Address);
          })
          .catch((error) => {
            if (error instanceof Error) {
              toast.error(error.message);
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error.info) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              toast.error(error.info as string);
            }
            console.error(error);

            localStorage.removeItem("selectedWallet");
            disconnect();
          });
      } else if (sessionStatus === "authenticated") {
        wallet
          .getLovelace()
          .then((adaValue) => {
            setCurrentBalance(parseInt(adaValue) / 1000000);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [address, connected, connectionRequested]);

  useEffect(() => {
    if (withdrawalUpdate_UserSent && withdrawalUpdate_UserSent.pending > 0) {
      setRefetchWithdrawal_UserSent(true);
    } else {
      if (sessionStatus === "authenticated") {
        if (toast.isActive("withdraw-toast")) {
          toast.success("We've received your fee.");
          toast.update("withdraw-toast", {
            render: "Sending your assets...",
          });
        }
        setRefetchWithdrawal_UserSent(false);
        setRefetchWithdrawal_AdminSent(true);
      }
    }
  }, [withdrawalUpdate_UserSent]);

  useEffect(() => {
    if (withdrawalUpdate_AdminSent && withdrawalUpdate_AdminSent.pending > 0) {
      setRefetchWithdrawal_AdminSent(true);
    } else {
      if (sessionStatus === "authenticated") {
        if (
          toast.isActive("withdraw-toast") &&
          // We are not waiting for user fees to be processed
          refetchWithdrawal_UserSent === false
        ) {
          toast.update("withdraw-toast", {
            render: "All pending withdrawals have been processed!",
            type: "success",
            isLoading: false,
            closeButton: true,
            autoClose: 5000,
          });
          successSound.play();
        }
        setRefetchWithdrawal_AdminSent(false);
        void refetchUserBalance();
      }
    }
  }, [withdrawalUpdate_AdminSentStatus]);

  useEffect(() => {
    if (depositUpdate && depositUpdate.pending > 0) {
      setRefetchDeposit(true);
    } else {
      if (sessionStatus === "authenticated") {
        if (toast.isActive("deposit-toast")) {
          toast.update("deposit-toast", {
            render: "All pending deposits have been processed!",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            closeButton: true,
          });
          successSound.play();
        }
        setRefetchDeposit(false);
        void refetchUserBalance();
      }
    }
  }, [depositUpdate]);

  const handleWalletSelection = async (myWallet: Wallet) => {
    localStorage.setItem("selectedWallet", JSON.stringify(myWallet));
    setSelectedWallet(myWallet);
    setConnectionRequested(true);
    await connect(myWallet.name);
  };

  const handleDisconnect = async () => {
    localStorage.removeItem("selectedWallet");
    disconnect();
    await signOut();
  };

  const { setIsFundModalOpen } = usePageStore();

  const DropDownOptions = [
    {
      text: "deposit",
      variant: "outline" as const,
      onClick: () => {
        setIsFundModalOpen(true);
        setOpenTab("deposit");
      },
      // customClass: "hover:bg-green-600/60",
    },
    {
      text: "withdraw",
      variant: "outline" as const,
      onClick: () => {
        setIsFundModalOpen(true);
        setOpenTab("withdraw");
      },
      // customClass: "hover:bg-destructive/90",
    },
    {
      text: "history",
      variant: "outline" as const,
      href: "/history",
      onClick: mobileCloseOnClick,
    },
    {
      text: "disconnect",
      variant: "destructive" as const,
      onClick: handleDisconnect,
    },
  ];

  return (
    <>
      <Button
        asChild
        className={cn(
          "group relative flex h-12 items-center rounded-lg border-2 border-white py-2 text-sm font-black uppercase text-white duration-300",
          connected ? "px-5" : "px-8",
        )}
        style={{
          width: connected ? 180 : "auto",
        }}
        variant="outline"
      >
        <div>
          {connected && selectedWallet ? (
            <li
              className="flex items-center space-x-2 py-2"
              key={selectedWallet.name}
            >
              <Image
                src={selectedWallet.icon}
                alt={selectedWallet.name}
                unoptimized={!selectedWallet.icon.startsWith("data:image")}
                width="30"
                height="30"
              />
              <span>{"₳ " + currentBalance.toFixed(2)}</span>
              <DownArrow className="w-12 duration-300 group-hover:rotate-180" />
            </li>
          ) : (
            <>
              Connect Wallet{" "}
              <DownArrow className="duration-300 group-hover:rotate-180" />
            </>
          )}
          {connected && (
            <>
              {DropDownOptions.map((button, idx) => {
                const btnClass =
                  "mt-2.5 w-full border-2 border-white px-5 py-2.5 capitalize text-white";
                return (
                  <div
                    key={idx}
                    className="absolute left-0 hidden w-full group-hover:block"
                    // Tailwind doesn't support dynamic classes (i.e. top-[40 + idx * 45px])
                    // soooo we'll just manually add it as a style
                    style={{ top: 40 + idx * 45 }}
                  >
                    {button.href ? (
                      <Link href={button.href}>
                        <Button
                          className={btnClass}
                          variant={button.variant}
                          onClick={button.onClick}
                        >
                          {button.text}
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        className={btnClass}
                        variant={button.variant}
                        onClick={button.onClick}
                      >
                        {button.text}
                      </Button>
                    )}
                  </div>
                );
              })}
            </>
          )}
          {!connected && (
            <div className="absolute left-0 top-[40px] hidden h-12 w-full bg-black/70 text-sm font-black uppercase duration-300 group-hover:block">
              {!connecting && (
                <ul className="rounded-lg border-2 border-white bg-black/70">
                  {walletLists.map((wallet) => (
                    <li
                      className="flex items-center space-x-2 bg-black/70 px-4 py-2 hover:cursor-pointer hover:bg-accent"
                      key={wallet.name}
                      onClick={() => handleWalletSelection(wallet)}
                    >
                      <Image
                        src={wallet.icon}
                        alt={wallet.name}
                        width="30"
                        height="30"
                        unoptimized={!wallet.icon.startsWith("data:image")}
                      />
                      <span className="h-12 py-3 text-white">
                        {wallet.name}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </Button>

      <FundModal onClose={() => setIsFundModalOpen(false)} tabName={openTab} />
    </>
  );
};

export default ConnectWallet;
