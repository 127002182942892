import { useState, useEffect } from "react";
import { Prisma, type SupportedToken, type Token } from "@prisma/client";
import type { DataSignature } from "@meshsdk/core";
import { Address } from "@emurgo/cardano-serialization-lib-asmjs";

export function sleep(duration: number): Promise<void> {
  return new Promise((resolve) => {
    const start = performance.now();

    function tick(timestamp: DOMHighResTimeStamp) {
      const elapsed = timestamp - start;
      if (elapsed >= duration) {
        resolve();
      } else {
        requestAnimationFrame(tick);
      }
    }

    requestAnimationFrame(tick);
  });
}

export function getRandomNumberInRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function generateRandomNumbers(length: number): number[] {
  const validNumbers = [1, 2, 3, 4, 5, 6, 7, 8];
  const randomNumbers: number[] = [];

  for (let i = 0; i < length; i++) {
    const randomIndex = getRandomNumberInRange(0, validNumbers.length - 1);
    const randomNumber = validNumbers[randomIndex]!;
    randomNumbers.push(randomNumber);
  }
  return randomNumbers;
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const getRankingData = () => {
  try {
    return [];
  } catch (error) {
    console.error(error);
  }
};

export function getCurrentCoinSettings(
  currentSelectedToken: SupportedToken,
  coinSettings: Token[],
): Token {
  const currentSetting = coinSettings.find(
    (item) => item.tokenName === currentSelectedToken,
  );

  if (!currentSetting) {
    return {
      id: "Error",
      tokenName: "ADA",
      policyId: "Error",
      minBet: new Prisma.Decimal(0),
      maxBet: new Prisma.Decimal(0),
      feeAmount: new Prisma.Decimal(0),
      decimals: 0,
      feeToken: "ADA",
      createdAt: new Date(),
      updatedAt: new Date(),
      withdrawals_enabled: false,
    };
  }

  return currentSetting;
}

export const signMessage = async (message: string, walletname: string) => {
  if (walletname.toLowerCase().includes(" wallet")) {
    console.log(
      "Wallet Name has 'Wallet' in it:",
      walletname,
      JSON.stringify(window.cardano, null, 2),
    );
    walletname = walletname.substring(0, walletname.length - 7).toLowerCase();
    console.log("After removing 'Wallet' in it:", walletname);
    if (`${walletname}cip30` in window.cardano) {
      walletname = walletname + "cip30";
      console.log("cip30 wallet version", walletname);
    }
  }
  const api = await window.cardano[walletname]?.enable();

  if (!api) {
    console.error(
      `Error connecting with '${walletname}' wallet. Current wallets: ${JSON.stringify(
        window.cardano,
        null,
        2,
      )}`,
    );
    throw new Error(`Error connecting with '${walletname}' wallet`);
  }

  const hexAddress = await api.getChangeAddress();
  const bech32Address = Address.from_hex(hexAddress).to_bech32();

  const { signature, key } = (await api.signData(
    hexAddress,
    Buffer.from(message).toString("hex"),
  )) as DataSignature;

  return { signature, key, bech32Address };
};
