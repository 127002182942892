import { type AppType } from "next/app";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";

import { api } from "~/utils/api";

import "~/styles/globals.css";
import { ToastContainer } from "react-toastify";
import { MeshProvider } from "@meshsdk/react";
import Head from "next/head";
import Navbar from "~/components/Navbar";
import { HighlightInit } from "@highlight-run/next/client";
import { env } from "~/env.mjs";
import { ErrorBoundary } from "~/components/Highlight/ErrorBoundary";

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  return (
    <>
      <ErrorBoundary>
        <SessionProvider session={session}>
          <MeshProvider>
            <Head>
              <title>Space Race</title>
              <link rel="icon" href="/favicon.ico" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
              />
            </Head>
            <HighlightInit
              projectId={env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
              version={process.env.npm_package_version}
              serviceName="spacerace-frontend"
              tracingOrigins
              networkRecording={{
                enabled: true,
                recordHeadersAndBody: true,
                urlBlocklist: [],
              }}
              excludedHostnames={["localhost"]}
              privacySetting="none"
            />
            <div className="flex min-h-screen flex-col">
              <Navbar />
              <main className="flex flex-grow flex-col items-center justify-center bg-background text-white">
                <Component {...pageProps} />
                <ToastContainer theme="colored" />
              </main>
            </div>
          </MeshProvider>
        </SessionProvider>
      </ErrorBoundary>
    </>
  );
};

export default api.withTRPC(MyApp);
