import { create } from "zustand";
import { type BalanceType } from "./types";
import { type SupportedToken } from "@prisma/client";

type GameState = {
  currentSelectedToken: SupportedToken;
  gameBalance: BalanceType;
  spinStatus: "started" | "finished";
  setUserGameBalance: (newBalance: BalanceType) => void;
  setCurrentSelectedToken: (newToken: SupportedToken) => void;
  increaseGameBalance: (token: SupportedToken, value: number) => void;
  decreaseGameBalance: (token: SupportedToken, value: number) => void;
  updateSpinStatus: (newStatus: "started" | "finished") => void;
};

export const useGameStore = create<GameState>()((set) => ({
  currentSelectedToken: "ADA",
  gameBalance: { ADA: 0, DUM: 0, NEBULA: 0, KONDA: 0, SNEK: 0 },
  setUserGameBalance: (gameBalance) =>
    set((state) => {
      return {
        gameBalance: {
          ...state.gameBalance,
          ...gameBalance,
        },
      };
    }),
  spinStatus: "finished",
  setCurrentSelectedToken: (newToken) =>
    set({ currentSelectedToken: newToken }),
  increaseGameBalance: (token, value) =>
    set((state) => ({
      gameBalance: {
        ...state.gameBalance,
        [token]: state.gameBalance[token] + value,
      },
    })),
  decreaseGameBalance: (token, value) =>
    set((state) => ({
      gameBalance: {
        ...state.gameBalance,
        [token]: Math.max(state.gameBalance[token] - value, 0),
      },
    })),
  updateSpinStatus: (newStatus) => set({ spinStatus: newStatus }),
}));

type PageState = {
  isFundModalOpen: boolean;
  setIsFundModalOpen: (value: boolean) => void;
};

export const usePageStore = create<PageState>()((set) => ({
  isFundModalOpen: false,
  setIsFundModalOpen: (value) => set({ isFundModalOpen: value }),
}));
