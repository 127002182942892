export const NEBULA_ALPHA_PASS_POLICY_ID =
  "f935b27359302c8a5516876918ac1ed62aca15a22c6ed45b15586c32";
export const NEBULA_ORACLE_POLICY_ID =
  "b765b901f8845f75d6ec976df3106208d2f3c7e6a1d8cd76c5c34dd8";

export const ADMIN_PERM_WALLETS = [
  "addr1q9r5uxpzrwp0grg8tpwcv5j6shxg9q5q977yxu07dg3vq9ld0egvzlprzmpr6kwe6hpj89z25wa32fkay7ewpfency9qkqxnzh",
  "addr1q866kj42u7l585m5z8pxgtwspsy50v7lrqwj4x5vplk8vsmqu4pege4zn8pxvv2p5q0dh9ghvw74v85g2caxq4edmeus0pgx6e",
];

export const ADMIN_WALLET_DB_ID = "ADMIN_WALLET";

export const TRANSACTION_EXPIRE_TIME = 3 * 60000;

// List of Blocked coutries
export const BLOCKED_COUNTRIES = [
  { code: "AF", name: "Afghanistan" },
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Algeria" },
  { code: "AO", name: "Angola" },
  { code: "AU", name: "Australia" },
  { code: "BS", name: "Bahamas" },
  { code: "BE", name: "Belgium" },
  { code: "BW", name: "Botswana" },
  { code: "BG", name: "Bulgaria" },
  { code: "CA", name: "Canada" },
  { code: "CO", name: "Colombia" },
  { code: "HR", name: "Croatia" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DK", name: "Denmark" },
  { code: "EC", name: "Ecuador" },
  { code: "EE", name: "Estonia" },
  { code: "ET", name: "Ethiopia" },
  { code: "FR", name: "France" },
  { code: "GH", name: "Ghana" },
  { code: "GY", name: "Guyana" },
  { code: "HK", name: "Hong Kong" },
  { code: "IR", name: "Iran" },
  { code: "IQ", name: "Iraq" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italy" },
  { code: "KW", name: "Kuwait" },
  { code: "LV", name: "Latvia" },
  { code: "LT", name: "Lithuania" },
  { code: "MX", name: "Mexico" },
  { code: "NA", name: "Namibia" },
  { code: "NI", name: "Nicaragua" },
  { code: "KP", name: "North Korea" },
  { code: "PK", name: "Pakistan" },
  { code: "PA", name: "Panama" },
  { code: "PH", name: "Philippines" },
  { code: "PT", name: "Portugal" },
  { code: "RO", name: "Romania" },
  { code: "SG", name: "Singapore" },
  { code: "ES", name: "Spain" },
  { code: "SD", name: "Sudan" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "SY", name: "Syria" },
  { code: "TW", name: "Taiwan" },
  { code: "TT", name: "Trinidad and Tobago" },
  { code: "TN", name: "Tunisia" },
  { code: "UG", name: "Uganda" },
  { code: "GB", name: "United Kingdom" },
  { code: "US", name: "United States" },
  { code: "YE", name: "Yemen" },
  { code: "ZW", name: "Zimbabwe" },
];

export const BANNED_WALLETS = [
  "addr1q86qxhat7m80ljxhhfk7lv48d0mhut7k73rlxlysxu6qny8e8k2fjdqwz9qxdhhfmc0cahtk2ex0udr34k8gcq2wd3qqzkvqcv",
  "addr1qy657lchk37u2y6hw38cap8dm6adv93rwzh0rn546aknxewa26nvfcsacxe3axu6yt7782mz6tagt5y5y543c6p74sfsmulkkw",
  "addr1q8t5dd23mq053fl8p5aexn3k6ztl7c3mupdnuamyt3pu3tca3aa279x8an0dxugprlgqej3dxxk9qf8j7ppxxu4c3stswje9tc",
  "addr1qxcqhlel9whug4rwr8kvydrnw5qxwkuc2efyunr0n90yuwfnw48664eppvyxnvrywfz4kzs4ya5kygfjfuwcml3sh5fs092mu0",
  "addr1qxxg9hkw4536cdh2cnrrh727dvv7dkg4w3fe66luzzfqcwp780e8jlpr47400t8h8vfvdseyczjhdtfyq7kqygddk62qxrfpdt",
  "addr1qyylrpvv6r7pgauqvz2qcjxt2xtj8hjma3gpcw64awdwvf4dk0lfu8d5g4nxp4sn5n0e38mgs8ldzv33kwcjzwtvsl0qwkpnju",
];
