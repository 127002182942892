import { type FC } from "react";

interface IconProps {
  color?: string;
  className?: string;
}
export const MinusIcon: FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585787C0.210714 0.96086 0 1.46957 0 2C0 2.53043 0.210714 3.03914 0.585786 3.41421C0.960859 3.78929 1.46957 4 2 4H14C14.5304 4 15.0391 3.78929 15.4142 3.41421C15.7893 3.03914 16 2.53043 16 2C16 1.46957 15.7893 0.96086 15.4142 0.585787C15.0391 0.210714 14.5304 0 14 0Z"
        fill={color ? color : "black"}
      />
    </svg>
  );
};

export const PlusIcon: FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6H10V2C10 1.46957 9.78929 0.960859 9.41421 0.585786C9.03914 0.210714 8.53043 0 8 0C7.46957 0 6.96086 0.210714 6.58579 0.585786C6.21071 0.960859 6 1.46957 6 2L6.071 6H2C1.46957 6 0.960859 6.21071 0.585786 6.58579C0.210714 6.96086 0 7.46957 0 8C0 8.53043 0.210714 9.03914 0.585786 9.41421C0.960859 9.78929 1.46957 10 2 10L6.071 9.929L6 14C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16C8.53043 16 9.03914 15.7893 9.41421 15.4142C9.78929 15.0391 10 14.5304 10 14V9.929L14 10C14.5304 10 15.0391 9.78929 15.4142 9.41421C15.7893 9.03914 16 8.53043 16 8C16 7.46957 15.7893 6.96086 15.4142 6.58579C15.0391 6.21071 14.5304 6 14 6Z"
        fill={color ? color : "black"}
      />
    </svg>
  );
};

export const CloseIcon: FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.44728 0.44728C0.733759 0.160887 1.12226 0 1.52734 0C1.93242 0 2.32092 0.160887 2.6074 0.44728L8.00922 5.8491L13.411 0.44728C13.6992 0.169003 14.0851 0.015023 14.4856 0.0185037C14.8862 0.0219843 15.2693 0.182647 15.5526 0.465889C15.8358 0.74913 15.9965 1.13229 15.9999 1.53284C16.0034 1.93339 15.8494 2.31928 15.5712 2.6074L10.1693 8.00922L15.5712 13.411C15.8494 13.6992 16.0034 14.0851 15.9999 14.4856C15.9965 14.8862 15.8358 15.2693 15.5526 15.5526C15.2693 15.8358 14.8862 15.9965 14.4856 15.9999C14.0851 16.0034 13.6992 15.8494 13.411 15.5712L8.00922 10.1693L2.6074 15.5712C2.31928 15.8494 1.93339 16.0034 1.53284 15.9999C1.13229 15.9965 0.74913 15.8358 0.465889 15.5526C0.182647 15.2693 0.0219843 14.8862 0.0185037 14.4856C0.015023 14.0851 0.169003 13.6992 0.44728 13.411L5.8491 8.00922L0.44728 2.6074C0.160887 2.32092 0 1.93242 0 1.52734C0 1.12226 0.160887 0.733759 0.44728 0.44728Z"
        fill={color ? color : "black"}
      />
    </svg>
  );
};

export const CoinIcon: FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 5.757L5.757 10L10 14.243L14.242 10L10 5.757Z"
        fill={color ? color : "#FFFF8B"}
      />
    </svg>
  );
};

export const DownArrow: FC<IconProps> = ({ color, className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.06 16.06C12.7787 16.3409 12.3975 16.4987 12 16.4987C11.6025 16.4987 11.2212 16.3409 10.94 16.06L5.282 10.404C5.00074 10.1226 4.84278 9.74098 4.84287 9.34312C4.84292 9.14612 4.88177 8.95106 4.9572 8.76908C5.03263 8.58709 5.14317 8.42174 5.2825 8.28248C5.42183 8.14321 5.58723 8.03275 5.76925 7.95741C5.95127 7.88206 6.14635 7.8433 6.34335 7.84335C6.54035 7.8434 6.73541 7.88224 6.9174 7.95768C7.09939 8.03311 7.26473 8.14364 7.404 8.28298L12 12.879L16.596 8.28298C16.7343 8.13965 16.8998 8.0253 17.0827 7.9466C17.2657 7.8679 17.4625 7.82643 17.6617 7.82461C17.8608 7.82278 18.0584 7.86064 18.2428 7.93598C18.4271 8.01131 18.5947 8.12261 18.7356 8.26338C18.8765 8.40415 18.9879 8.57158 19.0634 8.75589C19.139 8.94019 19.177 9.1377 19.1754 9.33686C19.1737 9.53603 19.1324 9.73288 19.0539 9.91592C18.9754 10.099 18.8612 10.2645 18.718 10.403L13.06 16.062L13.06 16.06Z"
        fill={color ? color : "yellow"}
      />
    </svg>
  );
};
