import Image from "next/image";
import ConnectWallet from "./ConnectWallet";
import Link from "next/link";
import MobileNav from "./MobileNavBar";
import { useState } from "react";
import ClickAwayComponent from "./ClickAwayComponent";
import { useSession } from "next-auth/react";
import { ADMIN_PERM_WALLETS } from "~/config";
import { env } from "~/env.mjs";
// import { useEffect } from "react";

const Navbar = () => {
  // useEffect(() => {
  //   // The debounce function receives our function as a parameter
  //   const debounce = (fn: () => void) => {
  //     // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  //     let frame: number;
  //     // The debounce function returns a new function that can receive a variable number of arguments
  //     return (...params: []) => {
  //       // If the frame variable has been defined, clear it now, and queue for next frame
  //       if (frame) {
  //         cancelAnimationFrame(frame);
  //       }
  //       // Queue our function call for the next frame
  //       frame = requestAnimationFrame(() => {
  //         // Call our function and pass any params we received
  //         fn(...params);
  //       });
  //     };
  //   };

  //   // Reads out the scroll position and stores it in the data attribute
  //   // so we can use it in our stylesheets
  //   const storeScroll = () => {
  //     document.documentElement.dataset.scroll = window.scrollY.toString();
  //   };

  //   // Listen for new scroll events, here we debounce our `storeScroll` function
  //   document.addEventListener("scroll", debounce(storeScroll), {
  //     passive: true,
  //   });

  //   // Update scroll position for first time
  //   storeScroll();
  // });

  const { data: sessionData } = useSession();

  const NavBarItems = [
    {
      title: "Play",
      href: "/",
      showRequirement: true,
    },
    {
      title: "Verify Game",
      href: "/verify",
      showRequirement: true,
    },
    {
      title: "Simulate Game",
      href: "/simulate",
      showRequirement: env.NEXT_PUBLIC_OWNER_WALLET.startsWith("addr_test"),
    },
    {
      title: "Admin",
      href: "/admin",
      showRequirement: ADMIN_PERM_WALLETS.includes(
        sessionData?.user.walletAddress ?? "",
      ),
    },
  ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <nav className="absolute left-0 top-0 z-20 hidden w-full bg-background/20 px-6 duration-300 md:block">
        <div className="container mx-auto max-w-7xl">
          <div className="flex flex-col items-center justify-between py-2 md:flex-row">
            <Link
              href="/"
              className="hue-rotate z-50 flex items-center space-x-3"
            >
              <Image
                src="/images/bottom-logo.png"
                width={100}
                height={100}
                alt="logo"
              />
              <p className="hidden text-4xl text-white md:block">
                Nebula Gaming
              </p>
            </Link>
            <div className="flex gap-5 ">
              {NavBarItems.map(
                (item) =>
                  item.showRequirement && (
                    <Link
                      key={item.title}
                      href={item.href}
                      className="border-2 border-input border-white bg-background px-4 py-3 text-foreground hover:bg-accent"
                    >
                      {item.title}
                    </Link>
                  ),
              )}
            </div>
            <ConnectWallet />
          </div>
        </div>
      </nav>
      <ClickAwayComponent onClickAway={() => setIsOpen(false)}>
        <MobileNav items={NavBarItems} isOpen={isOpen} setIsOpen={setIsOpen} />
      </ClickAwayComponent>
    </>
  );
};

export default Navbar;
